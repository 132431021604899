<template>
  <layout-horizontal>
 

    <keep-alive>
    <router-view />
</keep-alive>

  </layout-horizontal>

</template>

<script>
import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue'

export default {
  components: {
    LayoutHorizontal,
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>
