export default [
    {
      header: 'Más opciones',
      icon: 'ChevronDownIcon',
      children: [
          {
            title: 'Mapa de negocios afiliados',
            href:"https://backservicespagos.web.app/",
            icon: 'CheckSquareIcon',
            id: "mapa"
          },
        {
          title: 'Mis referidos',
          route: 'referidos',
          icon: 'MailIcon',
          id: "referidos"
        },

        {
          title: 'Empresas',
          route: 'negocio',
          icon: 'MailIcon',
          id: "negocio"
        },
       
        {
          title: 'Recargar billetera',
          route: 'recargar',
          icon: 'CheckSquareIcon',
          id: "recargas"
        },


          {
            title: 'Retiros de dinero',
            route: 'retiros',
            icon: 'MessageSquareIcon',
            id: "retiros"
          },
          {
            title: 'Marketplace',
            route: 'marketplace',
            icon: 'MailIcon',
            id: "marketplace"
          },
          {
            title: 'Transferencias interbilleteras',
            route: 'transferencias',
            icon: 'MailIcon',
            id: "interbilleteras"
          },

          {
            title: 'Transacciones',
            route: 'transacciones',
            icon: 'MessageSquareIcon',
            id: "transacciones"
          },
      ],
    },
  ]
  